const getters = {
  /*
     * Current user page or state for redirection
     * @param state
     * @returns {*}
     */
  getAuthUserSourcePage: state => {
    return state.authModuleParams.source_page_url
  },
  /*
     * Current user page or state query for redirection
     * @param state
     * @returns {*}
     */
  getAuthUserSourcePageQuery: state => {
    return state.authModuleParams.query
  },

  getUserProfile: state => {
    return state.userProfile
  },
  /*
     * Accessor for user logged in status
     * @param state
     * @returns {boolean}
     */
  isUserLoggedIn: state => {
    return !!Object.keys(state.token).length && !!Object.keys(state.userProfile).length
  },
  /*
     * Accessor for user onboarding form data
     * @param state
     * @returns {*}
     */
  getOnBoardingFormData: state => {
    return state.onBoardingFormData
  },
  /*
     * Email verified status
     * @returns {boolean}
     */
  isEmailVerified () {
    return !!this.getUserProfile.email_verified
  },

  /*
     * Email verified status
     * @returns {boolean}
     */
  isPurchasedSignUp: state => {
    return state.psSignUp
  },

  /*
   * Checks if the current user is a guest user.
   *
   * @returns {boolean} - Returns true if the user is a guest and does not have an IDP ID, otherwise false.
   */
  isGuestUser: state => {
    const { is_guest, idp_id } = state?.userProfile || {}
    return is_guest && !idp_id
  }
}

export default getters


export const PARTNERS = {
  monevo: 'monevo',
  carLoans: 'carloans',
  energyAustralia: 'energy-australia',
  agl: 'agl',
  origin: 'origin',
  other: 'Other'
}

export const PARTNERS_LIST = [
  {
    name: PARTNERS.monevo,
    hasCustomUtms: true,
    link: 'https://personalloans.compareclub.com.au/apply/'
  },
  {
    name: PARTNERS.carLoans,
    hasCustomUtms: false,
    link: '/personal-loans/quote/form/step1'
  }
]

export const ENERGY_PARTNERS_LIST = [
  {
    name: PARTNERS.energyAustralia,
    image: 'https://asset.compareclub.com.au/energy/partners/ENERGY-AUSTRALIA.svg',
    link: '/energy/quote/energy-type'
  },
  {
    name: PARTNERS.agl,
    image: 'https://asset.compareclub.com.au/energy/partners/AGL.svg',
    link: '/energy/quote/energy-type'
  },
  {
    name: PARTNERS.origin,
    image: 'https://asset.compareclub.com.au/energy/partners/ORIGIN.svg',
    link: '/energy/quote/energy-type'
  },
  {
    name: PARTNERS.other,
    image: '',
    link: '/energy/quote/energy-type'
  }
]

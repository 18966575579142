import { ApiService } from './api.service'
import { CONFIG } from '~/server/service/config'

class AuthService {
  constructor () {
    this.request = new ApiService()
  }

  /*
     * API service for checking for user existence
     * @param email
     * @returns {*}
     */
  userExistenceCheck = email => {
    return this.request.get('/user', {
      params: {
        email
      }
    })
  }

  /*
     * User Registration API service
     * @param formData
     * @returns {*}
     */
  register = formData => {
    return this.request.post('/register', formData)
  }

  /*
     * User Login API service
     * @param formData
     * @returns {*}
     */
  login = formData => {
    return this.request.post('/login', formData)
  }

  /*
     * User Profile API service
     * @param formData
     * @returns {*}
     */
  profile = formData => {
    return this.request.get('/profile', formData)
  }

  /*
     * google auth API service
     * @param formData
     * @returns {*}
     */
  googleAuthentication = formData => {
    this.setSourcePage(formData.state, '/authorization/google/login')
    return this.request.get('/authorization/google/login', { params: { state: formData.state } })
  }

  /*
     * Facebook auth API service
     * @param formData
     * @returns {*}
     */
  facebookAuthentication = formData => {
    this.setSourcePage(formData.state, '/authorization/facebook/login')
    return this.request.get('/authorization/facebook/login', { params: { state: formData.state } })
  }

  /*
     * LinkedIn auth API service
     * @param formData
     * @returns {*}
     */
  linkedinAuthentication = formData => {
    this.setSourcePage(formData.state, '/authorization/linkedin/login')
    return this.request.get('/authorization/linkedin/login', { params: { state: formData.state } })
  }

  /*
     * Social auth submission API service
     * @param formData
     * @returns {*}
     */
  submitSocialAuth = formData => {
    return this.request.get('/authorization/social/callback', {
      params: {
        code: formData.code, state: formData.state
      }
    })
  }

  /*
     * forgot password API service
     * @param formData
     * @returns {*}
     */
  forgotPassword = formData => {
    return this.request.post('/forgot-password', formData)
  }

  /*
     * Reset password API service
     * @param url
     * @param formData
     * @returns {*}
     */
  resetPassword = (url, formData) => {
    return this.request.post(url, formData)
  }

  /*
     * Merge accounts API service
     * @param payloadWithUrl
     * @returns {*}
     */
  merge = (payloadWithUrl, formData) => {
    return this.request.post(payloadWithUrl, formData)
  }

  /*
     * Logout API service
     * @returns {*}
     */
  logout = () => {
    return this.request.delete('/logout')
  }

  /*
     * Profile update API service
     * @param formData
     * @returns {*}
     */
  updateProfile = formData => {
    return this.request.patch('/profile', formData)
  }

  /*
     * Resent email user verification
     * @returns {*}
     */
  resendVerification = () => {
    return this.request.get('/resend-verification')
  }

  /*
     * Google one tap authentication API service
     * @param formData
     * @returns {*}
     */
  googleOneTapAuthentication = formData => {
    this.setSourcePage(formData.state, '/authorization/google/one-tap')
    return this.request.get('/authorization/google/one-tap', {
      params: {
        state: formData.state,
        id_token: formData.id_token
      }
    })
  }

  /*
     * Refresh token API service
     * @returns {*}
     */
  refreshToken = () => {
    const token = getLocal('token') ? JSON.parse(getLocal('token')) : {}
    return this.request.post(`/refresh-token/${token.refresh_token}`)
  }

  /*
     * Verify User with url itself API service
     * @param url
     * @returns {*}
     */
  verifyUser = url => {
    return this.request.post(url)
  }

  /*
     * Source page param rendering for SSO tracking
     * @param path
     * @returns {`${string}?ssoUrl=${*}${string}`}
     */
  setSourcePage = (state, path) => {
    const { environment } = useRuntimeConfig().public
    const route = useRoute()
    const utms = useUtmsStore()
    sessionStorage.setItem(state, JSON.stringify({
      source_page_url: location.href,
      sso_url: `${CONFIG.baseUrl(environment, 'memberhub')}${path}`,
      query: route.query,
      sso_path: path.replace('/authorization/', ''),
      source_page_path: location.pathname,
      utms: utms.utms
    }))
  }

  /*
     * Returns the logged in user's details
     * @returns {*}
     */
  userDetails = () => {
    return this.request.get('/user/details')
  }

  /*
   * Registers a guest user.
   *
   * @param {Object} formData - The form data for guest registration.
   * @returns {Promise} - The API response.
   */
  guestRegister = formData => {
    return this.request.post('/guest/register', formData)
  }

  /*
   * Guest Password Reset API service
   *
   * This method sends a POST request to the '/guest/password/reset' endpoint
   * to reset the password for a guest user.
   *
   * @param {Object} formData - The form data containing the necessary information for password reset.
   * @returns {Promise} - The API response.
   */
  guestPasswordReset = formData => {
    return this.request.post('/guest/password/reset', formData)
  }
}

export default AuthService

import state from './state.js'
import getters from './getters.js'
import actions from './actions.js'

export const useUtmsStore = defineStore('utms', {
  state,
  actions,
  getters,
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage()
  }
})

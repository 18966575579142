/*
 * This File is for intercepting requests using $fetch
*/
import { ofetch } from 'ofetch'
import { CONFIG } from '~/server/service/config'
import memberhubConstants from '~/constants/memberhub.constants'
import AuthService from '~/server/service/auth.service'
const authService = new AuthService()

export default defineNuxtPlugin(() => {
  const { environment } = useRuntimeConfig().public
  const currentExecutingRequests = ref({})
  const requestQueue = ref({})
  const isAlreadyFetchingAccessToken = ref(false)
  const subscribers = ref([])
  const { $toast } = useNuxtApp()
  globalThis.$fetch = ofetch.create({
    headers: useRequestHeaders(['cookie']),
    onRequest ({ request, options }) {
      // Only override the baseURL if the apiType is defined
      if (options.apiType) {
        options.baseURL = CONFIG.baseUrl(environment, options.apiType)
      }

      let currentUrl = request
      //  get access token from local storage for JWT
      const token = getLocal('token') ? JSON.parse(getLocal('token')) : {}
      const userHashId = getLocal('auth_user') ? JSON.parse(getLocal('auth_user')) : {}
      if (!currentUrl.includes('refresh-token') && !currentUrl.includes('reset-password') && token && token.access_token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token.access_token}`
        }
      }
      if (userHashId && userHashId.hash_id && !currentUrl.includes('reset-password')) {
        options.query = { ...options.query }
        /*
         | ===============================================================================
         | Restricting `hash_id` addition for urls which have `signature` as default param
         | ===============================================================================
         */
        if (!options.query?.signature) {
          options.query = {
            ...options.query,
            hash_id: userHashId.hash_id
          }
        }
      }

      /*
       | User spoofing case(s) handler
       | Functionality is handled in Club+ BE
       | @type {string}
       */
      const userSpoofEmail = getLocal('spoof_email')
      const userSpoofHash = getLocal('spoof_email_hash')
      if (userSpoofEmail) {
        options.query = {
          ...options.query,
          spoof_email: userSpoofEmail
        }
      } else if (userSpoofHash) {
        options.query = {
          ...options.query,
          spoof_hash: userSpoofHash
        }
      }
      const features = getLocal('features') ? JSON.parse(getLocal('features')) : {}
      if(features && Object.keys(features).length) {
        // Iterate over the localStorage keys
        for (const key in features) {
          options.query = {
            ...options.query,
            [key]: features[key]
          }
        }
      }

      // Log request
      // console.log('[fetch request]', request, options)

      // Do duplicate request cancellation if enabled
      if (options.canCancel) {
        if (currentUrl.includes('?')) {
          currentUrl = currentUrl.substr(0, currentUrl.indexOf('?'))
        }

        if (currentExecutingRequests.value[currentUrl]) {
          const cancelController = currentExecutingRequests.value[currentUrl]
          cancelController.abort() // Cancel the previous request
        }

        // Add the cancel method to the request object
        const cancelController = new AbortController()
        options.signal = cancelController.signal

        // Store the cancel controller for the current URL
        currentExecutingRequests.value[currentUrl] = cancelController
      }
      requestQueue.value[currentUrl] = currentUrl
    },

    // eslint-disable-next-line no-unused-vars
    onRequestError ({ request, options, error }) {
      if (error.name === 'AbortError') {
        // console.log('[fetch request error] Aborted by the user')
      }
      // Log error

      // eslint-disable-next-line no-console
      console.log('[fetch request error]', request, error)
    },

    onResponse ({ request, options, response }) {
      if (response && response.status) {
        const tmpBaseUrl = new URL(response.url).href
        const isMemberHubApi = tmpBaseUrl?.includes(CONFIG.baseUrl(environment, 'memberhub'))
        // Check if it is due to token expiry
        if ([memberhubConstants.API_TOKEN_EXPIRED, memberhubConstants.API_INVALID_TOKEN].includes(response.status) && !response.url.includes('logout') && isMemberHubApi) {
          // Get new access token if not already being requested
          if (!isAlreadyFetchingAccessToken.value) {
            isAlreadyFetchingAccessToken.value = true
            /*
           | Extracting base url from last request since interceptor is working as a standalone package
           | Because "api-services" cannot access "env" of other packages
           | Refresh token will be requested to the last used endpoint's base url
           | @type {string}
           */
            authService.refreshToken()
              .then(res => {
                const tokenData = res?.data?.value?.data
                if (tokenData) {
                  setLocal('token', JSON.stringify(tokenData))
                  isAlreadyFetchingAccessToken.value = false
                  subscribers.value = subscribers.value.filter(callback => callback(tokenData.access_token))
                  options.headers = {
                    ...options.headers,
                    Authorization: `Bearer ${tokenData.access_token}`
                  }
                  location.reload() // TODO : Temp fix added - need to add request queue system for proper user experience
                } else {
                  dispatchEvent('logout')
                  $toast.error('Token expired or invalid token')
                }
              })
              .catch(() => {
                dispatchEvent('logout')
                $toast.error('Token expired or invalid token')
              })
          }
          // console.log(subscribers.value)
          // return new Promise(resolve => {
          //   subscribers.value.push(accessToken => {
          //     options.headers.Authorization = 'Bearer ' + accessToken
          //     resolve($fetch(request, options))
          //   })
          // })
        }
        // remove requested url if responded
        // delete requestQueue.value[response.url]
        // return response data
      }

      if (options.canCancel) {
        let currentUrl = request
        if (currentUrl.includes('?')) {
          currentUrl = currentUrl.substr(0, currentUrl.indexOf('?'))
        }
        // Clean up the cancel controller when the request is settled
        delete currentExecutingRequests.value[currentUrl]
        delete requestQueue.value[currentUrl]
      }
    },
    onResponseError ({ request, options }) {
      if (options.canCancel) {
        let currentUrl = request
        if (currentUrl.includes('?')) {
          currentUrl = currentUrl.substr(0, currentUrl.indexOf('?'))
        }
        // Clean up the cancel controller when the request is settled
        delete currentExecutingRequests.value[currentUrl]
      }
      // // Return the custom error response
    }
  })
})


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93x9lJnZwaTWMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexRSEnBNWnOPMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guides1LDvpqybadMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93quU3yvO2ZkMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexhHio9haFNzMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbacklUgxwMS65QMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45us2677ZXrEuKMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackKxAnPaWgT9Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergee9dEtS7UPQMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as buyer_45protection_45claimhNK6fVQ7WXMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as terms_45and_45conditionsiDbodBBapUMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as comprehensiveFNEbozyInaMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexxRfoNbMJx8Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93pYdEESxzaqMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsnjNdSocLEbMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsOFjjN4aNjGMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as _91slug_93fa1b5ksOmrMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexeM6ZTDfz21Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexzyCtgsYnyRMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeBoTbL8qTc8Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintslOZBDPLlCTMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usI88sjJqo3cMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93ni62VNKsl9Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexQJJQSVBmkdMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93nk8HSqVPqLMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93AcvYxkq9TOMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsyDXMMusjYZMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as index1jZvxRk9C1Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offerloN8QggOsOMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexElVpbBKw45Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexqJQQvv0bB9Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexRK9Nq2X43lMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as index7tNUGtbH7FMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailszas6rb2DkMMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as completionkK8qngv581Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as detailsdaMGeIpqxbMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as _91step_93zGzr45WLsHMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93cGvVLzYZTcMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93VULYFNJv6fMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93ygu7uAp0fCMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_9303pWG5qvDQMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsUuVetGmFzTMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as rebateWJjQ9vTKIVMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as digital_45eicbyi9IYbGQeMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as erroriOke9vrj1zMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youqDhi1B7TjkMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as coffs_45harbourrCgENNA7pYMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as index3PeYNXjmQZMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93dOhMPDmTssMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsKakgIkWwU5Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45yougQIPOgOSZNMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93r1EMQTgqMeMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexc5qF27JlaDMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93W554bZERPKMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexx74ZWETK2zMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordq9BSH8daKXMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as set_45passworddJCUp9GI73Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backZebEEGUnfzMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as _91step_93P5myryGWu4Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredVHD607Y5JuMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexZHDZai7HK5Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexKe7rdDjRWPMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorlRgLYcFOiQMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indextWeZlrXBLCMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bHKaGnoG9MwMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatiICUULf4kWMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexn7SsfjqE6aMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addtVtBZPK5HgMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupESrLKg7VseMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45salenI0bFm6uubMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexTdojVQnTHiMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93hbor1omm6vMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as details0GyfQ5tDFlMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0ktN0ihoPNWMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as detailsXHwUfW8gKcMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as _91step_93aBs7K3V3KnMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as step0xbHGofKislMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexqwyC1Eh5hBMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youzbvnZTj4VNMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as index8Yp1gktVP7Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexKVz5lSfH80Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as index1PDfMjmBzAMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexgOTeBgarE4Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93n6tVAkWD7zMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93n2WM37IJC8Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationVOddZAUpIKMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsHJLjuSSqi6Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexYbmBDjLlBNMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsf4BmoPzqLmMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youEni82DbavnMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_930CGrNanrWRMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as index2i3ztx0OMHMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexaxJlZg3x7KMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45rates39w0SRd01cMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93fPSxqIc1j5Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsbUz56a3L0EMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youEGLXwQhyowMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as index7C2FXlys9ZMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/index.vue?macro=true";
import { default as existing_45customersg4uy25awnPMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as confirmationbnjWpSLKIaMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsnjQsKiDHdbMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexAgZkjeZwJiMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexEAokWx0CKLMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addGmnEvLLFBEMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youZxNiL6n3FPMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indext0LFfhHtiVMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successPlivg6PjdrMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_936gbjJIP61bMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsv0enbIh5w4Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as step0YVEPk99CDjMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as loginGLGB1logZ3Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93vWUneEKk6xMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93a2islGarzQMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as index3DPPPieYA2Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsOn5qV5y6B6Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexr2iPDZ4LzFMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_933I7btHVFCVMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailsX22FLcq9LEMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youicvUbvPQSWMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45you5BbXIEaztFMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93cbGwcUL2ssMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93d9reRla2YwMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93yJrj0VPwtnMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policycaMXVbEpxiMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registereoFVL1p3B5Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordQoovw4bAzTMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as sitemapNr3IyTjy5oMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as terms_45of_45useHGBDbX5oTsMeta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifydBkLCgyBG2Meta } from "/codebuild/output/src3832500966/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbacklUgxwMS65QMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93ni62VNKsl9Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsyDXMMusjYZMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index1jZvxRk9C1Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerloN8QggOsOMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexElVpbBKw45Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexqJQQvv0bB9Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexRK9Nq2X43lMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index7tNUGtbH7FMeta?.props ?? {"verificationNeeded":true},
    meta: index7tNUGtbH7FMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product(.*)*/:vertical()/additional-details",
    meta: additional_45detailszas6rb2DkMMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product(.*)*/:vertical()/completion",
    meta: completionkK8qngv581Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product(.*)*/details",
    meta: detailsdaMGeIpqxbMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product(.*)*/form/energy/step/:step()",
    meta: _91step_93zGzr45WLsHMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product(.*)*/form/generic/step/:step()",
    meta: _91step_93cGvVLzYZTcMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/health-insurance/step/:step()",
    meta: _91step_93VULYFNJv6fMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product(.*)*/form/home-loan/step/:step()",
    meta: _91step_93ygu7uAp0fCMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/life-insurance/step/:step()",
    meta: _91step_9303pWG5qvDQMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product(.*)*/lic-details",
    meta: lic_45detailsUuVetGmFzTMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product(.*)*/rebate",
    meta: rebateWJjQ9vTKIVMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordq9BSH8daKXMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passworddJCUp9GI73Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93aBs7K3V3KnMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginGLGB1logZ3Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registereoFVL1p3B5Meta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordQoovw4bAzTMeta || {},
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src3832500966/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]